@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables";

@layer components {
  .btn-primary {
    @apply py-[14px] px-4 w-full bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
    background-color: $primary;
  }
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
body {
  font-family: Inter, sans-serif;
  margin: 0;
}

table {
  table-layout: fixed;
}

td {
  width: 100px;
  overflow: hidden;
}

.loaderContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.496);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.loader {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 6px solid #fc0;
  animation: prixClipFix 1.5s linear infinite;
}
.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #030366;
}
.show-loader {
  display: flex;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

th {
  width: 20px;
}

.show-links a {
  color: #030366;
  cursor: pointer;
}

.show-links a:hover {
  text-decoration: underline;
}

img {
  object-fit: cover !important;
}

textarea {
  resize: none;
}

.story_preveiw_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
